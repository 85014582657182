.heading {
    margin-top: 75px;
    font-style: normal;
    font-weight: normal;
    font-size: 52px;
    line-height: 64px;
    width: 100%;
    
    color: #000000;
}

.container {
    width: 90%;
    max-width: 900px;
    margin: 0px auto;
}

.text-center {
    text-align: center;
}

.description-1 {
    margin-top: 35px;
}

.description-2 {
    margin-top: 30px;
    margin-bottom: 10px;
}

.browser-names {
    margin-top: 50px;
}

.supported-browser {
    width: 200px;
    height: 200px;
    display: inline-block;
}

.supported-browser>div {
    margin-top: 40px;
}

.supported-browser>div a {
    text-decoration: none;
    color: #276ef1;
}