* {
  padding: 0;
  margin: 0;
}

button, a, input, button:focus, a:focus, input:focus {
  outline: none;
}

html {
  height: 100%;
  /* font-size: 62.5%; */
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background-color: rgb(255, 255, 255) !important;
} */

body {
  background-color: white !important;
  font-family: "Cairo", sans-serif !important;
  font-weight: 400;
  font-size: 1.6rem;
  letter-spacing: 0.3px;
  line-height: 1.7rem;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

:root {
  --space: 1.5em 0;
  --space: 2em 0;
}

footer {
  /* Like the header, the footer will have a static height - it shouldn't grow or shrink.  */
  /* 0 flex-grow, 0 flex-shrink, auto flex-basis */
  flex: 0 0 auto;
  background-color: #025492;
  color: white;
}

.footer-title {
  font-size: 22px;
  font-weight: bold;
  line-height: 40px;
}

.swal2-container {
  z-index: 2000 !important;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Cairo", sans-serif;
}

.section-padding-top {
  padding-top: 75px;
}

.section-padding-bottom {
  padding-bottom: 75px;
}

.section-margin-bottom {
  margin-bottom: 90px;
}

.img-float {
  float: right;
}

.btn-round span {
  font-family: "Cairo", sans-serif;
  font-size: 16px;
  text-transform: capitalize;
}

.MuiButton-root {
  border-radius: 100px !important;
}

.header-nav {
  background-color: white;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 5%), inset 0 -1px 0 rgb(0 0 0 / 15%);
}

.header-nav-item {
  font-size: 18px;
  font-weight: bold;
  color: #025492 !important;
  margin-left: 10px;
  text-decoration: none;
}

.active {
  color: #ffa200 !important;
}

.navbar-nav a:hover {
  color: #ffa200;
  border-bottom: 2px solid #ffa200;
}

.navbar-nav a.active {
  color: #ffa200;
  border-bottom: 2px solid #ffa200;
}

.header-title {
  color: #025492;
  font-size: 45px;
  line-height: 70px;
  font-weight: bold;
}

.header-subtitle {
  color: #707070;
  font-size: 22px;
  line-height: 35px;
  font-family: "Tajawal", sans-serif;
  padding: 0px 9px;
}

.section-title {
  color: #025492;
  font-size: 45px;
  line-height: 103px;
  font-weight: bold;
}

.card-title {
  color: #737373;
  font-size: 16px;
  font-family: "Tajawal", sans-serif;
  line-height: 20px;
}

.feature-card {
  height: 270px;
  border-radius: 20px !important;
  width: 24%;
}

.feature-card:hover {
  border: 2px solid #025492 !important;
}

.featured-card {
  border-radius: 20px !important;
}

.featured-card:hover {
  border: 2px solid #025492 !important;
}

.featured-card-number {
  border-radius: 50%;
  padding: 10px 15px;
  background-color: #ffa200;
  font-weight: bold;
  color: white;
  font-size: 22px;
  /* right: 15px;
  top: 5px; */
}

/* .featured-card-title {
  top: 13px;
  right: 50px;
} */

.terms-subtitle {
  color: #707070;
  font-size: 20px;
  line-height: 40px;
  text-align: initial;
}

.terms-title {
  color: #025492;
  font-size: 20px;
  line-height: 40px;
  font-weight: 600;
  text-align: initial;
}

.price-card {
  min-height: 450px;
  padding: 15px 10px;
  width: calc(100% + 26px) !important;
}

.price-card:hover {
  background-image: linear-gradient(180deg, #025492, #a9defe);
  transform: scale(1.05);
}

.price-card-title {
  font-size: 27px !important;
  font-weight: bold !important;
  font-family: "Cairo", sans-serif !important;
}

.background-color-primary {
  background-color: #eaf3fa;
}

.border-primary-color {
  border-color: #025492 !important;
}

.text-initial {
  text-align: initial !important;
}

.secondary-color {
  color: #ffa200;
}

.primary-color {
  color: #025492;
}

.font-family-cairo {
  font-family: "Cairo", sans-serif !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-25 {
  font-size: 25px !important;
}

/* .MuiInput-underline:before {
  border: 0 !important;
} */

.price-card-price {
  font-size: 21px !important;
  font-weight: bold !important;
  font-family: "Cairo", sans-serif !important;
  color: #3d3d3d;
}

.price-card-features span {
  font-size: 14px !important;
  font-family: "Cairo", sans-serif !important;
  color: #737373 !important;
}

.custom_visibility {
  display: block;
}

.custom_visibility_versed_app_bar {
  display: none;
}

.custom_visibility_versed {
  display: none;
}

.logo {
  text-align: initial;
  width: 60%;
}

.navbar-toggler {
  font-size: 2rem !important;
}

.feature-img {
  width: 80%;
}

.form-title {
  color: #025492;
  font-size: 50px;
  font-weight: bold;
  text-align: initial;
}

.form-input label {
  font-size: 15px !important;
  font-family: "Cairo", sans-serif !important;
  text-align: initial;
}

.MuiAlert-filledError {
  font-size: 18px !important;
  font-family: "Cairo", sans-serif !important;
}

.MuiAlert-icon {
  font-size: 25px;
}

.MuiTypography-body1 {
  font-family: "Cairo" !important;
}

.footer-content {
  font-family: "Cairo";
  font-size: 14px;
}

.MuiTypography-h6 {
  font-family: "Cairo" !important;
}

.MuiStepLabel-label.MuiStepLabel-active {
  color: rgba(2, 84, 146, 0.87) !important;
  font-weight: bold !important;
}

.MuiStepLabel-label {
  font-family: "Cairo" !important;
}

.MuiButton-label {
  font-family: "Cairo" !important;
}

.MuiTab-wrapper {
  font-family: 'Cairo';
}

.MuiTypography-h5 {
  font-family: 'Cairo' !important;
  text-align: initial;
}

.MuiTypography-h6 {
  text-align: initial;
}

.MuiTypography-overline {
  font-family: 'Cairo' !important;
}

.MuiTypography-h4 {
  font-family: 'Cairo' !important;
  font-size: 20px !important;
  font-weight: bold !important;
}

.price-card-free {
  font-size: 30px !important;
  font-weight: bold !important;
  font-family: "Cairo", sans-serif !important;
}

.logo-container {
  text-align: end;
  width: 70%;
}

.terms-subtitle p {
  text-align: justify;
}

.list-item span {
  font-family: "Cairo", sans-serif;
  font-size: 16px;
  text-transform: none;
}

.faq-subtitle {
  color: #707070;
  font-size: 16px;
  line-height: 25px;
  text-align: initial;
}

@media screen and (max-width: 1000px) {
  .feature-card {
    width: 70%;
    margin: 20px 0px;
  }
  .logo {
    width: 100%;
  }
}

@media screen and (max-width: 750px) {
  .feature-card {
    width: 100%;
    margin: 20px 0px;
  }
  .price-card {
    min-height: 500px;
  }
  .logo {
    width: 70%;
  }
  .feature-img {
    margin-top: 150px;
  }
}

@media screen and (max-width: 585px) {
  .logo {
    width: 25%;
  }
}

@media (min-width: 320px) {
  .custom_visibility {
    display: none;
  }
  .custom_visibility_versed_app_bar {
    display: block;
  }
  .custom_visibility_versed {
    display: block;
  }
  .logo-container {
    text-align: initial;
  }
  .section-title {
    font-size: 40px;
    line-height: 70px;
  }
  /* smartphones, iPhone, portrait 480x320 phones */
}

@media (min-width: 481px) {
  .custom_visibility {
    display: none;
  }
  .custom_visibility_versed_app_bar {
    display: block;
  }
  .custom_visibility_versed {
    display: block;
  }
  .logo-container {
    text-align: initial;
  }
  .section-title {
    font-size: 40px;
    line-height: 70px;
  }
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}

@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .custom_visibility {
    display: block;
  }
  .custom_visibility_versed_app_bar {
    display: block;
  }
  .custom_visibility_versed {
    display: none;
  }
  .logo-container {
    text-align: initial;
  }
  .section-title {
    font-size: 40px;
    line-height: 70px;
  }
}

@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .custom_visibility {
    display: block;
  }
  .custom_visibility_versed_app_bar {
    display: none;
  }
  .custom_visibility_versed {
    display: none;
  }
  .logo-container {
    text-align: end;
  }
}

@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
  .custom_visibility {
    display: block;
  }
  .custom_visibility_versed_app_bar {
    display: none;
  }
  .custom_visibility_versed {
    display: none;
  }
  .logo-container {
    text-align: end;
  }
}

@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
  .custom_visibility {
    display: block;
  }
  .custom_visibility_versed_app_bar {
    display: none;
  }
  .custom_visibility_versed {
    display: none;
  }
  .logo-container {
    text-align: end;
  }
}

.react-calendar__tile{
  position: relative;
}
.react-calendar button {
  padding-top: 15px;
  padding-bottom: 15px;
}
.react-calendar {
  max-width: 500px !important;
  width: 100% !important;
  font-size: 18px;
}